import fetch from '../utils/fetch'


export function cardverifyExport(query) {
  return fetch({
    url: '/cardverify/export',
    method: 'post',
    params: query
  })
}
export function getMemberPoint(query) {
  return fetch({
    url: '/point/member',
    method: 'get',
    params: query
  })
}

export function getPointCountIndex(query) {
  return fetch({
    url: '/member/pointcount/index',
    method: 'get',
    params: query
  })
}

export function adjustmentPoint(query) {
  return fetch({
    url: '/point/adjustment',
    method: 'post',
    params: query
  })
}
export function cardCodeList(query) {
  return fetch({
    url: '/xmz/card_code/list',
    method: 'get',
    params: query
  })
}
export function cardCodeVerify(query) {
  return fetch({
    url: '/xmz/card_code/verify',
    method: 'get',
    params: query
  })
}
