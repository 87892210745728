<style lang="scss">

</style>

<template>
  <div class="ym-shop-list">
    <div class="shop-bar">
      <el-radio-group v-model="date_type" @change="beginChangeRaido">
        <el-row>
              <el-radio label="DATE_TYPE_FIX_TIME_RANGE">
                <span>指定日期</span>
              </el-radio>
              <el-date-picker
                v-model="endTime"
                type="datetime"
                placeholder="结束时间"
                style="width: 384px"
                format="yyyy/MM/dd HH:mm"
                value-format="timestamp"
                :disabled="date_type=='DATE_TYPE_FIX_TERM'"
                @change="changeValues"
              />
        </el-row>
        <el-row style="display: flex;align-items:center">
            <el-radio label="DATE_TYPE_FIX_TERM">
              <span>领取后</span>
            </el-radio>
            <el-input v-model="fixed_term" type="Number" :disabled="date_type=='DATE_TYPE_FIX_TIME_RANGE'" placeholder="请填写天数" style="width: 200px;" @change="changeFixedTerm">
              <template #append>天过期</template>
            </el-input>
            <!-- <span style="margin:auto 10px; font-size: 14px;">统一过期时间</span>
            <el-date-picker
              v-model="endTime"
              type="datetime"
              placeholder="结束时间"
              style="width: 384px"
              format="yyyy/MM/dd HH:mm"
              value-format="timestamp"
              :disabled="date_type=='DATE_TYPE_FIX_TIME_RANGE'"
              @change="changeValues"
            /> -->
        </el-row>
        </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YmSelectShop',
  props: {
    value: [Number],
    def_date_type:[String],
    def_fixed_term: [Number],
    placeholder: String,
    clearable: Boolean
  },
  data() {
    return {
      date_type:'DATE_TYPE_FIX_TIME_RANGE',
      endTime:null,
      fixed_term:30
    }
  },
  computed: {

  },
  watch: {
    value(newVal, oldVal) {
      // 重置
      //console.log('newVal',newVal)
      if(newVal){
        this.$data.endTime = newVal;
      }
    },
    def_date_type(newVal, oldVal) {
      // 重置
      console.log('def_date_type newVal',newVal)
      if(newVal){
        this.$data.date_type = newVal;
      }
    },
    def_fixed_term(newVal, oldVal) {
      // 重置
      console.log('def_date_type newVal',newVal)
      if(newVal){
        this.$data.fixed_term = newVal;
      }
    }
  },
  created() {
  },
  methods: {
    changeValues(time) {
      //console.log('time',typeof time,time)
      //this.$emit('changeIds',idsStr)
      this.$emit('input', time)
    },
    changeFixedTerm(val){
      //console.log('changeFixedTerm',val)
      this.$emit('changeFixedTerm',Number(val))
    },
    beginChangeRaido(e){
     // console.log('e',e)
      this.$emit('changeEndDateType', e)
    }
  }
}
</script>
