<style lang="scss">
.ym-shop-list {
  .empt{font-size: 12px; color:#999;}
  .poipt{
    max-width: 100%;
    .el-input__inner{
      border-color: #d9d9d9;
    }
  }
  .htext{
    line-height: 1.5;
  }
  .htips{
    font-size: 12px;
    color: #999;
  }
  .filter-tools{
    margin-bottom: 10px;
  }
  .shop-search-list-bar{
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    .el-tag{
      margin-right: 5px;
      height: auto;
      &:hover{
        background-color: #ecf5ff;
        color: #409eff;
      }
    }
    .info{
      display: inline-block;
      vertical-align:top;
      padding:10px;
      .text{
        display: block;
        line-height: 1.5;
      }
    }

  }
}
</style>

<template>
  <div class="ym-shop-list">
    <div class="shop-bar">
      <el-radio-group v-model="shopradio" @change="changeShopRadio">
          <el-radio label="0">全部门店</el-radio>
          <el-radio label="1">部分门店</el-radio>
        </el-radio-group>
    </div>

    <div class="shop-search-bar" v-if="shopradio=='1'">
      <div class="filter-tools">
        <el-input placeholder="请输入门店poi_id,多个以英文','号分隔" v-model="poi_id" class="poipt" @keyup.enter.native="handleFetch">
            <el-select v-model="cartype" slot="prepend" placeholder="请选择" style="width: 100px;" @change="changeCartype">
              <el-option label="添加门店" value="push"></el-option>
              <el-option label="搜索门店" value="find"></el-option>
            </el-select>
            <el-button slot="append" @click="handleFetch">
              {{cartype=='push'?'添加门店':'搜索已添加门店'}}
            </el-button>
        </el-input>
      </div>

      <el-card shadow="never" >
        <el-row slot="header">
          <el-col :span="20">
            <div class="htext">{{cartype=='push'?'添加门店列表':"搜索门店列表"}}</div>
            <div class="htext htips">当前已添加门店  <el-link type="danger" :underline="false">{{seList.length}}</el-link> 家</div>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-button @click="resetSlist" v-if="cartype=='find'">返回添加列表</el-button>
          </el-col>
        </el-row>
        <div class="shop-search-list-bar" v-if="list.length">
          <el-tag v-for="(tag,index) in list" :key="tag.distributor_id" type="info" closable @close="removeTag(tag.poi_id)">
            <div class="info">
              <span class="text">{{tag.name}}</span>
              <span class="text">poi_id: {{tag.poi_id}}</span>
            </div>
          </el-tag>
        </div>
         <div class="empt" v-else>{{loading?'加载中':tipstext}}</div>
      </el-card>

    </div>
  </div>
</template>

<script>
export default {
  name: 'YmSelectShop',
  props: {
    value: [String],
    placeholder: String,
    clearable: Boolean
  },
  data() {
    return {
      poi_id:'',
      list: [],
      seList:[],
      loading: false,
      selected: [],
      shopradio:'0',
      initVal:true,
      tipstext:'',
      cartype:'push'
    }
  },
  computed: {

  },
  watch: {
    value(newVal, oldVal) {
      // 重置
      //console.log('this.$data',newVal)
      if(newVal!='0' && this.initVal){
        var selected = newVal.split(',')//平台上架
        this.$data.selected = selected;
        this.$data.shopradio = '1';
        this.initFetch()
      }
    }
  },
  created() {

  },
  methods: {
    //保存的门店回显
    async initFetch(){
      if(this.$props.value){
        const params = {
          distributor_ids:this.$props.value
        }
        if(params.distributor_ids!='0'){
          //console.log('initFetch params',params)
          this.loading = true
          const { total_count, list } = await this.$api.marketing.getDistributorList(params);
          if(list.length){
            this.resetList(list)
            this.total = total_count
          }else{
            this.tipstext = '暂无门店';
          }
          this.loading = false
        }else{
          console.log('initFetch 未选择门店',params,this.$props)
        }
      }
    },
    //添加
    async fetch() {
      this.loading = true
      const {poi_id} = this;
      const params = {
        poi_id
      }
      var tipstext = ''
      if(!params.poi_id){
        this.$message.warning('请填写poi_id编号')
        return false;
      }
      //console.log('fetch value',this.$props.value)
      const { total_count, list } = await this.$api.marketing.getDistributorList(params);
      if(list.length){
        this.total = total_count
        this.resetList(list)
        this.changeValues()
      }else{
        tipstext = '未找到门店';
        this.$message.warning('未找到门店')
      }
      this.tipstext = tipstext
      this.loading = false
    },
    //查找已有的
    findSelList(){
      const {poi_id,seList} = this;
      var slist = []
      var tipstext = ''
      if(!poi_id){
        this.$message.warning('请填写poi_id编号')
        return false;
      }
      var poi_arr = poi_id.split(',');
      if(poi_arr.length && seList.length){
        seList.forEach(item=>{
          if(poi_arr.includes(item.poi_id)){
            slist.push(item)
          }
        })
      }
      if(slist.length==0){
        tipstext = '未找到相应门店';
      }
      //console.log('poi_arr',poi_arr,slist,seList)
      this.list = slist;
      this.tipstext =tipstext
      this.poi_id = '';
    },
    handleFetch(){
      const {cartype} = this;
      if(cartype=='push'){
        this.fetch()
      }else{
        this.findSelList()
      }
    },
    //重写去重
    resetList(list){
      var uniqueJsonArr =[ ]
      try{
        // 先合并
        let slist = list.map(item=>{return {name:item.name,poi_id:item.poi_id,distributor_id:item.distributor_id}})
        let nlist = [...this.seList,...slist]
        let nlistNum = list.length+this.list.length;
        //console.log('slist',nlist,slist)
        // 转json文本
        let duplicates = nlist.map(item=>{return JSON.stringify(item)});
        // 使用Set数据结构来去除重复元素
        let remove_duplicates = [...new Set(duplicates)]
        //console.log('remove_duplicates',remove_duplicates,duplicates)
        // 将去重后的数组转换回JSON格式
        uniqueJsonArr =  remove_duplicates.map(item=>{return JSON.parse(item)});
        //console.log('uniqueJsonArr',uniqueJsonArr)
        if(nlistNum!=uniqueJsonArr.length){
          this.$message.warning('门店重复添加')
        }
      }catch(err){
        console.log('resetList err',err)
      }
      this.list = [...uniqueJsonArr];
      this.seList = [...uniqueJsonArr];
      this.poi_id = ''
    },
    //删除
    removeTag(poi_id){
      const {list,seList} = this.$data;
      let l_idx = list.findIndex(item=>item.poi_id==poi_id)
      let se_idx = seList.findIndex(item=>item.poi_id==poi_id)

      console.log('removeTag idx',l_idx,se_idx)
      if(l_idx!=undefined && list.length){
        this.list.splice(l_idx,1)
        console.log('removeTag list',this.list)
        console.log('removeTag seList',this.seList)
      }
      if(se_idx!=undefined && seList.length){
        this.seList.splice(se_idx,1)
        console.log('removeTag list2',this.list)
        console.log('removeTag seList2',this.seList)
      }

      let tipstext = ''
      if(this.list.length==''){
        tipstext = '暂无门店'
      }
      this.$nextTick(()=>{
        this.tipstext = tipstext
        this.changeValues()
      })
    },
    changeCartype(){
      const {cartype} = this;
      let tipstext = '';
      let nlist = []
      if(cartype=='push'){
        nlist = [...this.seList]
      }
      this.list = nlist
      if(this.list.length==''){
        tipstext = '暂无门店'
      }
      this.tipstext = tipstext;
    },
    resetSlist(){
      this.cartype = 'push';
      this.changeCartype()
    },
    changeShopRadio(val){
      console.log('changeShopRadio',val)
      if(val=='0'){
        this.$emit('input','0')
        this.$data.selected = []
      }else{
        this.$emit('input','')
      }
    },
    changeValues() {
      var ids = this.seList.map(item=>item.distributor_id);
      var idsStr = ids.toString()||''
      console.log('changeValues ids',idsStr)
      this.$data.initVal = false;
      this.$emit('input', idsStr)
    }
  }
}
</script>
